<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        If you had 0.100 M solutions of each of the following compounds, rank them in terms of the
        resulting pH.
      </p>

      <drag-to-rank-input
        v-model="inputs.relativePh"
        :items="items"
        class="mb-5 pl-8"
        style="max-width: 400px"
        prepend-text="Highest pH"
        append-text="Lowest pH"
      />

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n5">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question135',
  components: {
    STextarea,
    CalculationInput,
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        relativePh: [],
        explainedResponse: null,
      },
      items: [
        '$\\ce{HBrO2(aq)}$',
        '$\\ce{Ba(OH)2(aq)}$',
        '$\\ce{HBr(aq)}$',
        '$\\ce{NH3(aq)}$',
        '$\\ce{NaOH(aq)}$',
      ],
    };
  },
};
</script>
